var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticClass: "grey lighten-4" },
    [
      _c("page-toolbar", {
        ref: "toolbar",
        attrs: { title: "File Storage" },
        scopedSlots: _vm._u([
          {
            key: "contents",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "v-card",
        { staticClass: "d-flex", attrs: { id: "card" } },
        [
          _c(
            "div",
            { staticStyle: { width: "50%" } },
            [
              _c("v-treeview", {
                staticStyle: { padding: "30px" },
                attrs: {
                  id: "tutti-file-storage-treeview",
                  "open-on-click": "",
                  activatable: "",
                  "return-object": "",
                  active: _vm.activeItem,
                  open: _vm.openDirs,
                  items: _vm.files,
                  "item-key": "path",
                  "load-children": _vm.loadChildren,
                },
                on: {
                  "update:active": function ($event) {
                    _vm.activeItem = $event
                  },
                  "update:open": function ($event) {
                    _vm.openDirs = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "prepend",
                    fn: function ({ item, open }) {
                      return [
                        item.type === "dir"
                          ? _c("v-icon", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    open ? "mdi-folder-open" : "mdi-folder"
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("v-icon", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getIconStringForFile(item.name)) +
                                  " "
                              ),
                            ]),
                      ]
                    },
                  },
                  {
                    key: "label",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          [
                            _c("span", { staticClass: "mr-4" }, [
                              _vm._v(
                                _vm._s(item.name) +
                                  _vm._s(item.type === "dir" ? "/" : "")
                              ),
                            ]),
                            _c(
                              "v-menu",
                              {
                                attrs: { "offset-y": "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ attrs, on }) {
                                        return [
                                          item.type === "dir"
                                            ? _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        small: "",
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [
                                                      _vm._v(
                                                        "mdi-dots-vertical"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "v-list",
                                  { attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.openDialog(
                                              "createFolder",
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { small: "", left: "" },
                                              },
                                              [_vm._v("mdi-folder-plus")]
                                            ),
                                            _vm._v("New Folder"),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    item.name !== "static"
                                      ? _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.openDialog(
                                                  "renameFile",
                                                  item,
                                                  item.name
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-title",
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      left: "",
                                                    },
                                                  },
                                                  [_vm._v("mdi-pencil")]
                                                ),
                                                _vm._v("Rename"),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.name !== "static"
                                      ? _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.openDeleteFileDialog(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-title",
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      left: "",
                                                    },
                                                  },
                                                  [_vm._v("mdi-delete")]
                                                ),
                                                _vm._v("Delete"),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.openUploadDialog(
                                              "file",
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { small: "", left: "" },
                                              },
                                              [_vm._v("mdi-upload")]
                                            ),
                                            _vm._v("Upload File(s)"),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.openUploadDialog(
                                              "folder",
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { small: "", left: "" },
                                              },
                                              [_vm._v("mdi-upload")]
                                            ),
                                            _vm._v("Upload Folder"),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("v-divider", { attrs: { vertical: "" } }),
          _c(
            "div",
            { attrs: { id: "file-preview" } },
            [
              _vm.activeItem.length > 0
                ? [
                    ["jpg", "jpeg", "png", "gif", "bmp", "ico"].includes(
                      _vm.getFileExtension(_vm.activeItem[0].path)
                    )
                      ? _c("img", {
                          attrs: {
                            id: "preview-img",
                            src: `/static/${_vm.activeItem[0].path}`,
                          },
                        })
                      : _vm._e(),
                    ["wav", "mp3", "m4a"].includes(
                      _vm.getFileExtension(_vm.activeItem[0].path)
                    )
                      ? _c("audio", {
                          attrs: {
                            controls: "",
                            id: "preview-audio",
                            src: `/static/${_vm.activeItem[0].path}`,
                          },
                        })
                      : _vm._e(),
                    ["mp4", "webm"].includes(
                      _vm.getFileExtension(_vm.activeItem[0].path)
                    )
                      ? _c("video", {
                          attrs: {
                            controls: "",
                            id: "preview-video",
                            src: `/static/${_vm.activeItem[0].path}`,
                          },
                        })
                      : _vm._e(),
                    _c("v-divider"),
                    _c("v-simple-table", { attrs: { dense: "" } }, [
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("File path")]),
                          _c("td", [
                            _vm._v("/static/" + _vm._s(_vm.activeItem[0].path)),
                          ]),
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("URL")]),
                          _c(
                            "td",
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.getUrlForPath(
                                      _vm.activeItem[0].path
                                    ),
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getUrlForPath(
                                          _vm.activeItem[0].path
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "v-icon",
                                { attrs: { small: "", right: "" } },
                                [_vm._v("mdi-open-in-new")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ attrs, on }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ma-4",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.download(
                                                    _vm.activeItem[0]
                                                  )
                                                },
                                              },
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v("mdi-download")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2812828999
                            ),
                          },
                          [_c("span", [_vm._v("Download")])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ attrs, on }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ma-4",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openDeleteFileDialog(
                                                    _vm.activeItem[0]
                                                  )
                                                },
                                              },
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v("mdi-delete")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4286835197
                            ),
                          },
                          [_c("span", [_vm._v("Delete")])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ attrs, on }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ma-4",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openDialog(
                                                    "renameFile",
                                                    _vm.activeItem[0],
                                                    _vm.activeItem[0].name
                                                  )
                                                },
                                              },
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v("mdi-pencil")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3604162294
                            ),
                          },
                          [_c("span", [_vm._v("Rename")])]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("input", {
        ref: "uploadFilesForm",
        staticStyle: { display: "none" },
        attrs: { multiple: "", type: "file" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return (() => {}).apply(null, arguments)
          },
          change: (e) => {
            _vm.onSelectUploadedFiles("file", e)
          },
        },
      }),
      _c("input", {
        ref: "uploadFolderForm",
        staticStyle: { display: "none" },
        attrs: { webkitdirectory: "", type: "file" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return (() => {}).apply(null, arguments)
          },
          change: (e) => {
            _vm.onSelectUploadedFiles("dir", e)
          },
        },
      }),
      _c("tutti-dialog-alert", {
        ref: "dialog",
        on: {
          confirm: function ($event) {
            return _vm.onDialogConfirm(_vm.activeDialogItem.path, _vm.nameInput)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.dialogTitle) + " ")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "v-card-text",
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.dialogInputLabel,
                        rules: _vm.dialogInputRules,
                      },
                      model: {
                        value: _vm.nameInput,
                        callback: function ($$v) {
                          _vm.nameInput = $$v
                        },
                        expression: "nameInput",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("tutti-dialog-alert", {
        ref: "dialogDeleteFile",
        on: {
          confirm: function ($event) {
            return _vm.deleteFile(_vm.activeDialogItem.path)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("v-icon", { attrs: { color: "error", left: "" } }, [
                  _vm._v("mdi-alert"),
                ]),
                _vm._v("Delete " + _vm._s(_vm.activeDialogItem.name) + " "),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("v-card-text", [
                  _vm._v(" Are you sure to delete "),
                  _c("b", [_vm._v(_vm._s(_vm.activeDialogItem.name))]),
                  _vm._v("?"),
                  _c("br"),
                  _vm._v(" This operation cannot be undone. "),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("tutti-dialog-alert", {
        ref: "dialogUpload",
        on: { confirm: _vm.uploadFiles, cancel: _vm.onCancelUpload },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("v-icon", { attrs: { color: "warning", left: "" } }, [
                  _vm._v("mdi-alert"),
                ]),
                _vm._v("Some files already exist "),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("v-card-text", [
                  _vm._v(
                    " The following files already exist in the path. Do you wish to overwrite them?"
                  ),
                  _c("br"),
                  _vm._v(" This operation cannot be undone. "),
                  _c("textarea", {
                    attrs: { readonly: "", id: "textarea-existing-file-paths" },
                    domProps: {
                      innerHTML: _vm._s(_vm.existingFilePaths.join("\n")),
                    },
                  }),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("tutti-snackbar", { ref: "snackbar" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }